
import { defineComponent } from "vue";
import {
  getQualifiedWebsiteName,
  getRestaurantsAndVotes,
  googleUrl,
} from "../../common/common";
import { getDistanceFromLatLonInMiles } from "../../utilities/distanceCalcs";
import EventService from "@/services/EventService";
import VotingService from "@/services/VotingService";
import { TYPE, useToast } from "vue-toastification";
import { showAddVotingOptionModal } from "../Modals/ModalController";
import eventBus from "../EventBus";

export default defineComponent({
  data() {
    return { voteableItems: [], lat: null, long: null, event: null };
  },
  async mounted() {
    await this.setRestaurantsAndVenues();
    const r = await EventService.getEventDetails(this.$route.params.eventId);
    this.lat = r.data.event.sessionLat;
    this.long = r.data.event.sessionLong;
    this.event = r.data.event;
    eventBus.$on("voting-option-created", async () => {
      await this.setRestaurantsAndVenues();
    });
  },
  beforeUnmount() {
    eventBus.$off("voting-option-created");
  },
  computed: {
    qualifiedWebsiteName() {
      // Check if the website starts with 'https://' and 'www.', if not, add them
      let formattedWebsite = this.website;
      if (!formattedWebsite.startsWith("https://")) {
        formattedWebsite = "https://" + formattedWebsite;
      }
      if (!formattedWebsite.includes("//www.")) {
        formattedWebsite = formattedWebsite.replace("//", "//www.");
      }
      return formattedWebsite;
    },
    restaurants() {
      return this.voteableItems.filter((v) => v.specialType === "g");
    },
    venues() {
      return this.voteableItems.filter(
        (v) => v.specialType === "n" || v.specialType === "w"
      );
    },
    userInputVotingOptions() {
      return this.voteableItems.filter((v) => v.specialType === "u");
    },
    userInputVotingItems() {
      return this.voteableItems.filter((v) => v.specialType === "u");
    },
  },
  methods: {
    getQualifiedWebsiteName,
    async loadMoreRestaurants() {
      try {
        await this.$store.dispatch("setLoading", true);
        const { restaurants, venues, userInputVotingOptions } =
          await getRestaurantsAndVotes(this.event.eventId, true, true);
        this.updateVoteableItems(restaurants, venues, userInputVotingOptions);
        const r = await this.getEventDetails();
        this.event = r.data.event;
      } catch (e) {
        console.log(e);
      } finally {
        await this.$store.dispatch("setLoading", false);
      }
    },
    showAddVotingOptionModal,
    calculatedDistance(placeLat, placeLong) {
      if (this.lat && this.long) {
        return getDistanceFromLatLonInMiles(
          +placeLat,
          +placeLong,
          +this.lat,
          +this.long
        );
      } else {
        return "";
      }
    },
    updateVoteableItems(restaurants, venues, userInputVotingItems) {
      this.voteableItems = [];
      for (const r of restaurants) {
        this.voteableItems.push({ ...r, specialType: "g", parent: null });
      }
      for (const u of userInputVotingItems) {
        this.voteableItems.push({ ...u, specialType: "u", parent: null });
      }
      for (const v of venues) {
        for (const s of v.Specials) {
          this.voteableItems.push({ ...s, specialType: "w", parent: v });
        }
        for (const n of v.NonWeeklySpecials) {
          this.voteableItems.push({ ...n, specialType: "n", parent: v });
        }
      }
    },
    async setRestaurantsAndVenues() {
      try {
        await this.$store.dispatch("setLoading", true);

        const { restaurants, venues, userInputVotingOptions } =
          await getRestaurantsAndVotes(this.$route.params.eventId);
        this.updateVoteableItems(restaurants, venues, userInputVotingOptions);
      } catch (e) {
        console.log(e);
      } finally {
        await this.$store.dispatch("setLoading", false);
      }
    },
    googleUrl,
    async deleteRestaurant(id: string, type: string, name: string) {
      if (
        confirm(
          "Are you sure you want to delete " +
            name +
            " and all associated votes?"
        )
      ) {
        try {
          this.$store.dispatch("setLoading", true);
          const response = await EventService.deleteFromVote(
            this.$route.params.eventId,
            id,
            type,
            name
          );
          const toast = useToast();

          toast("Successfully deleted " + name, {
            timeout: 5000,
            type: TYPE.SUCCESS,
            hideProgressBar: true,
          });

          await this.setRestaurantsAndVenues();
        } catch (e) {
          console.log(e);
        } finally {
          this.$store.dispatch("setLoading", false);
        }
      }
    },
  },
});
